<template>
  <div class="modal is_small" :class="{ 'is-active': active }">
    <div class="modal-background"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <div class="columns is-multiline">
          <div class="column is-12 has-text-centered">
            <span class="material-icons is-danger att_icon">
              report_gmailerrorred
            </span>
            <h2 class="subtitle">
              ¿Esta seguro que desea eliminar este archivo?
            </h2>
            <div class="field is-grouped">
              <p class="control">
                <button class="button" @click="close()">
                  Cancelar
                </button>
              </p>
              <p class="control">
                <button
                  class="button is-danger"
                  :class="{'is-loading': loading}"
                  @click="confirmationAction(documentRemove)"
                >
                  Eliminar
                </button>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalConfirmDelete',
  props: {
    active: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: () => {}
    },
    documentRemove: {
      type: Number,
      default: null
    },
    confirmationAction: {
      type: Function,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
  h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 1.29;
    letter-spacing: 0.72px;
    text-align: center;
    color: #5d6990;
    margin-bottom: 3rem;
  }
  .field.is-grouped {
    margin-top: 2rem;
    justify-content: center;
  }
  .att_icon {
    font-size: 40px;
    margin-bottom: 2rem;
  }
</style>
